<template>
  <div>
    <Navgation bgColor="#060B4C" :isPageHome="true" />
    <div
      :class="{
        'option-cell2': true,
        fixed: true,
        'move-top': isShowXD,
        'move-hide': isShowXD2,
      }"
      v-show="isShowAdsorption"
    >
      <div class="form-cell">
        <div class="hm-cell" >
          <div class="form-item form-ml">
            <el-input
              v-model.trim="joinForm.city"
              placeholder="请输入您所在的城市"
              maxlength="11"
            ></el-input>
          </div>
          <div class="form-item form-ml">
            <el-input
              v-model.trim="joinForm.name"
              placeholder="请输入您的姓名"
              maxlength="11"
            ></el-input>
          </div>
          <div class="form-item form-ml form-mr">
            <el-input
             v-model.number="joinForm.mobile"
              placeholder="请输入您的手机号"
              maxlength="11"
              minlength="11"
            ></el-input>
          </div>
          <div class="submit-btn" @click="applyJoin">提交</div>
        </div>
      </div>
    </div>
    <div class="product-sjsdzjqr-container">
      <section class="banner">
        <div class="banner-content">
          <div class="banner-text">
            <div class="banner-text-title">自助报税，三年仅需1580元</div>
            <div class="banner-text-introduce">
              十角兽代账机器人，轻松3步，一键报税
            </div>
            <div class="banner-text-qr">
              <img
                class="qr-img"
                src="@/assets/images/product/dzjqr-qr.png"
                alt=""
              />
              <div class="qr-label">扫码体验</div>
            </div>
            <!-- <div class="join-form-box" >
              <div class="join-form">
                <el-form ref="joinFormRef" :model="joinForm" label-width="0px">
                  <el-form-item>
                    <el-input
                      v-model.trim="joinForm.city"
                      placeholder="请输入您所在的城市"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-input
                      v-model.trim="joinForm.name"
                      placeholder="请输入您的姓名"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                  >
                    <el-input
                      v-model.number="joinForm.mobile"
                      placeholder="请输入您的手机号"
                      maxlength="11"
                      minlength="11"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <div class="apply-btn" @click="applyJoin">提交</div>
              </div>
            </div> -->
          </div>
          <img
            class="phone-img"
            src="@/assets/images/product/dzjqr-phone.png"
            alt=""
          />
        </div>
      </section>
      <!-- @mouseenter="videoMouseEnter"
        @mouseleave="videoMouseLeave" -->
      <section class="sjsdzjqr-video">
        <!-- @click="videoClick" -->
        <div class="video-shadow"></div>
        <video
          ref="sjsdzjqrVideRef"
          src="/videos/jzbs.mp4"
          autoplay
          loop
          muted
        ></video>
        <!-- <div v-show="isShowMask" class="video-mask" @click="videoClick">
          <div
            class="play-btn"
            @click.stop="videoPlay"
            v-show="!videoPlayStatus"
          >
            <img
              class="play-img"
              src="@/assets/images/product/play.png"
              alt=""
            />
          </div>
          <div
            class="play-btn"
            @click.stop="videoPause"
            v-show="videoPlayStatus"
          >
            <img
              class="play-img"
              src="@/assets/images/product/pause.png"
              alt=""
            />
          </div>
        </div> -->
      </section>
      <section class="service">
        <div
          class="service-content"
          :class="{
            'pre-hide-100': true,
            'move-bottom-100': isShowService,
          }"
        >
          <div class="service-title section-title">十角兽代账机器人可以：</div>
          <!-- <div class="service-label">
            全场景全链路的企业工商财税产品，助力企业发展
          </div> -->
          <div class="service-module">
            <div
              @mouseover="serviceHoverIndex = serviceModuleIndex"
              class="service-module-item"
              v-for="(serviceModuleItem, serviceModuleIndex) in serviceModules"
              :key="serviceModuleItem.title"
            >
              <!-- <div
                class="service-module-hover-item-content"
                v-if="serviceHoverIndex === serviceModuleIndex"
              >
                <div class="service-module-hover-item-content-line"></div>
                <div class="service-module-hover-item-content-text">
                  {{ serviceModuleItem.content }}
                </div>
                <div
                  class="service-module-hover-item-content-hint"
                  v-if="serviceModuleItem.hint"
                >
                  {{ serviceModuleItem.hint }}
                </div>
              </div> -->
              <div class="service-module-item-content">
                <img :src="serviceModuleItem.img" alt="" />
                <div class="service-module-item-content-title">
                  {{ serviceModuleItem.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="jzbs">
        <div
          class="jzbs-content"
          :class="{
            'pre-hide-100': true,
            'move-bottom-100-1': isShowJzbs,
          }"
        >
          <div class="jzbs-title section-title">您每月只需要</div>
          <div class="jzbs-steps">
            <div
              class="jzbs-steps-item"
              v-for="(item, index) in jzbsList"
              :key="'total' + index"
            >
              <div class="item-box">
                <img class="item-img" :src="item.img" alt="" />
                <!-- <div class="item-title">{{ item.title }}</div> -->
                <div class="item-content">{{ item.content }}</div>
              </div>
              <img
                v-if="index !== jzbsList.length - 1"
                class="right-arrow"
                src="@/assets/images/partner/right-arrow.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section class="advantage">
        <div
          class="advantage-content"
          :class="{
            'pre-hide-100': true,
            'move-bottom-100-2': isShowAdvantage,
          }"
        >
          <div class="advantage-title section-title">产品优势</div>
          <div class="advantage-modules">
            <div class="advantage-modules-left">
              <div class="advantage-modules-left-title">十角兽代理记账</div>
              <div class="advantage-modules-left-list">
                <div class="advantage-modules-left-list-item">
                  <img src="@/assets/images/product/advantage-1.png" alt="" />
                  <span>仅需600元/年</span>
                </div>
                <div class="advantage-modules-left-list-item">
                  <img src="@/assets/images/product/advantage-2.png" alt="" />
                  <span>7*24h财税专家1v1咨询 </span>
                </div>
                <div class="advantage-modules-left-list-item">
                  <img src="@/assets/images/product/advantage-3.png" alt="" />
                  <span>3步完成记账报税</span>
                </div>
                <div class="advantage-modules-left-list-item">
                  <img src="@/assets/images/product/advantage-4.png" alt="" />
                  <span>数据实时在线查看</span>
                </div>
              </div>
            </div>
            <div class="advantage-modules-right">
              <div class="advantage-modules-right-title">个人、代账公司</div>
              <div class="advantage-modules-right-list">
                <div class="advantage-modules-right-list-item">
                  一年两三千元
                </div>
                <div class="advantage-modules-right-list-item">回复消息慢</div>
                <div class="advantage-modules-right-list-item">流程节点多</div>
                <div class="advantage-modules-right-list-item">数据不透明</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import Navgation from '@/layouts/components/Navgation'
import getApp from '@/utils/getApp.js'
import partnerApi from '@/api/partner.api.js'
const imgIp = getApp.getData().imgIp
import {pageSeoInfo} from '@/config'
export default {
  name: 'dzjqr',
  components: { Navgation },
  inject: ['getPosition', 'scrollTo'],
  data() {
    return {
      isShowMask: false,
      videoPlayStatus: true,
      joinForm: {
        name: '',
        mobile: '',
        city: '',
        type: '记账报税'
      },
      serviceModules: [
        {
          title: '自动采集发票回单信息',
          img: require('@/assets/images/product/dzjqr-ctcsh.png'),
        },
        {
          title: '自动生成电子记账凭证',
          
          img: require('@/assets/images/product/dzjqr-yhlsgl.png'),
        },
        {
          title: '自动记账生成电子账簿',
          
          img: require('@/assets/images/product/dzjqr-pjgl.png'),
        },
        {
          title: '自动计算每月申报税额',
          img: require('@/assets/images/product/dzjqr-aqsbns.png'),
        },
        {
          title: '自动完成申报反馈截图',
          img: require('@/assets/images/product/dzjqr-xtzxzc.png'),
        },
        {
          title: '自动完成在线税款缴纳',
          img: require('@/assets/images/product/dzjqr-xcbgl.png'),
        },
        {
          title: '自动完成每年工商年检',
          img: require('@/assets/images/product/dzjqr-gsnj.png'),
        },
        {
          title: '自动完成每年汇算清缴',
          img: require('@/assets/images/product/dzjqr-hsqjsb.png'),
        },
      ],
      serviceHoverIndex: 0,
      jzbsList: [
        {
          title: '核对',
          content: '核对采集发票数量金额 核对银行流水回单信息',
          img: require('@/assets/images/partner/process-icon4.png'),
        },
        {
          title: '确认',
          content: '确认本月是否增减人员 一键确认开始自动记账',
          img: require('@/assets/images/partner/process-icon5.png'),
        },
        {
          title: '申报',
          content: '一键确认开始自动报税 一键完成线上缴纳税款',
          img: require('@/assets/images/partner/process-icon6.png'),
        },
      ],
      isShowService: false,
      isShowJzbs: false,
      isShowAdvantage: false,
      videoUrl: imgIp + 'cc698b8b27cf480dbafb539b4f43eceb',
      isShowXD: false,
      isShowXD2: false,
      isShowAdsorption: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, true)
  },
  methods: {
    onScroll() {
      const { scrollTop } = this.getPosition()
      const clientHeight = document.documentElement.clientHeight
      if (clientHeight <= 1150) {
        if (scrollTop >= 2400) {
          this.isShowAdvantage = true
        } else if (scrollTop >= 1800) {
          this.isShowJzbs = true
        } else if (scrollTop >= 800) {
          this.isShowService = true
        }
      } else {
        if (scrollTop >= 2650) {
          this.isShowAdvantage = true
        } else if (scrollTop >= 2050) {
          this.isShowJzbs = true
        } else if (scrollTop >= 1050) {
          this.isShowService = true
        }
      }
      if (scrollTop >= 600) {
        this.isShowXD2 = true;
        this.isShowXD = false;
        if (this.scrollTimer) {
          clearTimeout(this.scrollTimer);
          this.scrollTimer = null;
        }
        this.oldScroll = scrollTop;
        this.scrollTimer = setTimeout(() => {
          this.handleScroll(scrollTop);
        }, 700);
      } else {
        this.oldScroll = 0;
        this.newScroll = 0;
        this.isShowXD2 = true;
        this.isShowXD = false;
      }
    },
    handleScroll(scrollTop) {
      if (!this.isShowAdsorption) {
        this.isShowAdsorption = true;
      }
      this.newScroll = scrollTop;
      if (this.newScroll == this.oldScroll) {
        this.isShowXD2 = false;
        this.isShowXD = true;
        clearTimeout(this.scrollTimer);
        this.scrollTimer = null;
        this.oldScroll = 0;
        this.newScroll = 0;
      }
    },
    applyJoin() {
      if (this.joinForm.name && this.joinForm.mobile && this.joinForm.city) {
        if (typeof this.joinForm.mobile !== 'number') {
          this.$message.warning('请输入正确的手机号')
          return false
        }
        if (this.joinTimer) {
          clearTimeout(this.joinTimer)
          this.joinTimer = null
        }
        this.joinTimer = setTimeout(() => {
          partnerApi
            .sendJoinMessageV2({
              ...this.joinForm,
              mobile: this.joinForm.mobile + ''
            })
            .then((res) => {
              this.$message.success('提交成功')
              this.joinForm.mobile = ''
              this.joinForm.name = ''
              this.joinForm.city = ''
            })
            .catch((err) => {
              this.$message.error('提交失败')
            })
            .finally(() => {
              clearTimeout(this.joinTimer)
              this.joinTimer = null
            })
        }, 1000)
      }
    },
    videoClick() {
      if (this.videoPlayStatus) {
        this.$refs.sjsdzjqrVideRef.pause()
      } else {
        this.$refs.sjsdzjqrVideRef.play()
      }
      this.videoPlayStatus = !this.videoPlayStatus
    },
    videoMouseEnter() {
      this.isShowMask = true
    },
    videoMouseLeave() {
      this.isShowMask = false
    },
    videoPlay() {
      this.$refs.sjsdzjqrVideRef.play()
      this.videoPlayStatus = true
    },
    videoPause() {
      this.$refs.sjsdzjqrVideRef.pause()
      this.videoPlayStatus = false
    },
  },
  metaInfo: {
    title: pageSeoInfo.product.title,
    meta: [{
      name: 'keywords',
      content: pageSeoInfo.product.keywords
    },
    {
      name: 'description',
      content: pageSeoInfo.product.description
    }]
  },
}
</script>
<style lang="less" scoped>
.product-sjsdzjqr-container {
  // margin-top: @navHeight;
  .section-title {
    font-size: 48px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #102a55;
  }
  .banner {
    height: 728px;
    background: linear-gradient(177deg, #023176 0%, #0a1265 18%, #060b4c 100%);
    .banner-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      .phone-img {
        width: 520px;
        height: 624px;
        align-self: flex-end;
        margin-right: 64px;
      }
      .banner-text {
        margin-top: 140px;
        position: relative;
        &-title {
          font-size: 42px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          // line-height: 95px;
          margin-bottom: 8px;
          background: linear-gradient(
            270deg,
            #51ffc4 0%,
            #24eaff 40%,
            #1464ff 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        &-introduce {
          margin-top: 30px;
          margin-bottom: 70px;
          font-size: 20px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #ffffff;
        }
        &-qr {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .qr-img {
            // width: 80px;
            // height: 80px;
            width: 130px;
            height: 130px;
          }
          .qr-label {
            margin-left: 35px;
            margin-top: 18px;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.6);
            line-height: 21px;
          }
        }
      }
      .join-form-box {
        position: absolute;
        z-index: 2;
        top: 140px;
        // left: calc(50% - 186px);
        left: 0px;
        // width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .join-form {
          width: 356px;
          height: 356px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 17px;
          backdrop-filter: blur(25px);
          padding: 34px 44px;
          box-sizing: border-box;
          .el-form {
            /deep/.el-form-item {
              margin-bottom: 0px;
              .el-form-item__content {
                .el-input {
                  .el-input__inner {
                    height: 50px;
                  }
                }
              }
            }
            .el-form-item + .el-form-item {
              margin-top: 24px;
            }
          }
          .apply-btn {
            margin: 36px auto 0px;
            width: 160px;
            height: 50px;
            background: #2871ff;
            border-radius: 9px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  .sjsdzjqr-video {
    position: relative;
    width: 100%;
    height: 822px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    .video-shadow{
      width: 990px;
      height: 562px;
      background: #080E57;
      opacity: 0.16;
      filter: blur(26px);
      margin: 0 auto;
      position: absolute;
      left: 50%;
      top: 130px;
      transform: translateX(-495px);
      z-index: 1;
    }
    video {
      object-fit: fill;
      // width: 100%;
      width: 1024px;
      height: 576px;
      margin: 0 auto;
      // height: 800px;
      position: relative;
      z-index: 2;
      border-radius: 10px;
    }
    .video-mask {
      width: 100%;
      height: 100%;
      background-color: rgba(6, 11, 76, 0.45);
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
    }
    .play-btn {
      width: 94px;
      height: 94px;
      position: absolute;
      z-index: 2;
      top: calc(50% - 47px);
      left: calc(50% - 47px);
      .play-img {
        width: 94px;
        height: 94px;
      }
    }
  }
  .service {
    height: 800px;
    background: #f6f9fe;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .service-title {
        margin-top: 66px;
        font-size: 48px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #102A55;
      }
      .service-label {
        margin-top: 20px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(16, 42, 85, 0.6);
      }
      .service-module {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        &-item {
          width: 238px;
          height: 226px;
          background: #ffffff;
          border-radius: 22px;
          margin-right: 24px;
          margin-bottom: 24px;
          &:nth-of-type(4n) {
            margin-right: 0px;
          }
          &-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
              width: 56px;
              height: 56px;
            }
            &-title {
              width: 120px;
              text-align: center;
              margin-top: 10px;
              margin-bottom: 8px;
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(16, 42, 85, 1);
              line-height: 25px;
            }
          }
        }
        &-hover-item {
          background: #2871ff;
          &-content {
            height: 100%;
            padding: 24px 24px 10px 24px;
            box-sizing: border-box;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            display: flex;
            flex-direction: column;
            &-line {
              margin-top: 6px;
              margin-bottom: 30px;
              width: 58px;
              height: 1px;
              background: #ffffff;
            }
            &-text {
              color: #ffffff;
              line-height: 20px;
              flex: 1;
            }
            &-hint {
              color: rgba(255, 255, 255, 0.5);
              margin-bottom: 10px;
              // margin-bottom: 18px;
            }
          }
        }
      }
    }
  }
  .jzbs {
    height: 800px;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .jzbs-title {
        margin-top: 198px;
      }
      .jzbs-steps {
        width: 1024px;
        height: 260px;
        margin-top: 80px;
        background: #ffffff;
        box-shadow: 0px 0px 138px 0px rgba(171, 190, 214, 0.2);
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0px 60px;
        box-sizing: border-box;
        .jzbs-steps-item {
          display: flex;
          justify-content: center;
          .item-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 210px;
            .item-img {
              width: 98px;
              height: 98px;
            }
            .item-title {
              font-size: 24px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #102a55;
              line-height: 33px;
            }
            .item-content {
              margin-top: 6px;
              width: 180px;
              height: 44px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #102A55;
              line-height: 22px;
              // opacity: 0.5;
              text-align: center;
            }
          }
          .right-arrow {
            width: 50px;
            height: 260px;
            margin-left: 28px;
          }
        }
      }
    }
  }
  .advantage {
    height: 1214px;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .advantage-title {
        margin-top: 54px;
      }
      .advantage-modules {
        margin-top: 106px;
        display: flex;
        align-items: center;
        &-left {
          position: relative;
          z-index: 2;
          width: 512px;
          height: 598px;
          background: linear-gradient(
            313deg,
            #51e0ff 0%,
            #328bff 53%,
            #2871ff 100%
          );
          box-shadow: 22px 0px 37px 0px rgba(49, 84, 153, 0.18);
          border-radius: 58px;
          display: flex;
          flex-direction: column;
          padding-left: 106px;
          box-sizing: border-box;
          &-title {
            margin-top: 72px;
            width: 300px;
            height: 60px;
            background: #ffffff;
            border-radius: 40px;
            font-size: 26px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #3a7be7;
            text-align: center;
            line-height: 60px;
          }
          &-list {
            margin-top: 82px;
            margin-left: 24px;
            &-item {
              font-size: 20px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              display: flex;
              align-items: center;
              img {
                width: 32px;
                height: 32px;
                margin-right: 12px;
              }
            }
            .advantage-modules-left-list-item
              + .advantage-modules-left-list-item {
              margin-top: 48px;
            }
          }
        }
        &-right {
          position: relative;
          left: -52px;
          width: 528px;
          height: 484px;
          background: linear-gradient(90deg, #ffffff 0%, #e7f2ff 100%);
          box-shadow: 0px 15px 87px 0px rgba(49, 84, 153, 0.09);
          border-radius: 58px;
          display: flex;
          flex-direction: column;
          align-items: center;
          &-title {
            margin-top: 62px;
            width: 300px;
            height: 60px;
            background: linear-gradient(
              90deg,
              rgba(73, 203, 255, 0.06) 0%,
              rgba(38, 131, 248, 0.06) 100%
            );
            border-radius: 40px;
            font-size: 26px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #20427c;
            text-align: center;
            line-height: 60px;
          }
          &-list {
            margin-top: 38px;
            &-item {
              font-size: 20px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: rgba(16, 42, 85, 0.65);
            }
          }
          .advantage-modules-right-list-item
            + .advantage-modules-right-list-item {
            margin-top: 48px;
          }
        }
      }
    }
  }
  .pre-hide-100 {
    transform: translateY(100px);
    opacity: 0;
  }
  .pre-hide-300 {
    transform: translateY(300px);
    opacity: 0;
  }
  .move-bottom-100 {
    animation: move-bottom-100 1s 0.2s forwards ease-in-out;
  }
  .move-bottom-100-1 {
    animation: move-bottom-100-1 1s 0.2s forwards ease-in-out;
  }
  .move-bottom-100-2 {
    animation: move-bottom-100-2 1s 0.2s forwards ease-in-out;
  }
  @keyframes move-bottom-100 {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes move-bottom-100-1 {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes move-bottom-100-2 {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}

.option-cell2 {
    .name-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 22px;
      .name-txt {
        width: 146px;
        height: 51px;
        padding-top: 13px;
        box-sizing: border-box;
        flex-shrink: 1;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        cursor: pointer;
      }
      .name2 {
        margin: 0 10px;
      }
      .active {
        color: #060b4c;
        background-image: url("../assets/images/2.0/name-bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
    &.prefixed {
      animation: option-top-hide 1s forwards ease-in-out;
    }
    &.fixed {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 13;
      width: 100%;
      height: 106px;
      background: rgba(6, 11, 76, 0.5);
      transform: translateY(-300px);
      .name-cell {
        padding-top: 40px;
        margin-bottom: 22px;
      }
      .form-cell {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 24px;
        .qm-cell,
        .hm-cell {
          height: 68px;
          padding-left: 5px;
          .form-item {
            height: 34px;
            &.form-ml {
              margin-left: 10px;
            }
            &.form-mr {
              margin-right: 10px;
            }
          }
          .submit-btn {
            margin-right: 15px;
            cursor: pointer;
          }
        }
        .cm-cell {
          height: 68px;
          // padding-left: 5px;
          .form-item {
            height: 42px;
          }
          .submit-btn {
            margin-right: 15px;
          }
        }
        .hm-cell {
          width: auto;
          .form-txt {
            margin-right: 10px;
          }
          .submit-btn {
            margin-right: 15px;
            margin-left: 15px;
          }
        }
        .submit-btn {
          height: 42px;
        }
        .hm-cell {
          display: flex;
          justify-content: center;
          align-items: center;
          // min-width: 870px;
          height: 58px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 17px;
          backdrop-filter: blur(25px);
          // padding: 0 3px;
          .form-item {
            width: 240px;
            height:34px;
            // background: #FFFFFF;
            border-radius: 9px;
            flex-shrink: 1;

            &.form-ml {
              margin-left: 12px;
            }
            &.form-mr {
              margin-right: 15px;
            }
            .el-select,
            .el-cascader {
              width: 100%;
              height: 100%;
              /deep/ .el-input {
                height: 100%;
                // background: transparent;
                .el-input__inner {
                  height: 100%;
                  // background: transparent;
                  font-size: 15px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #060b4c;
                  // border: 1px solid rgba(255, 255, 255, 0.25);
                  border-radius: 9px;
                  &::placeholder {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(16, 42, 85, 0.4);
                    // color: red;
                    letter-spacing: -1px;
                  }
                }
                .el-input__suffix {
                  // color: #fff;
                  color: rgba(26, 26, 26, 1);
                  .el-input__icon {
                    // color: #fff;
                    color: rgba(26, 26, 26, 1);
                  }
                }
              }
            }
            .el-input {
              width: 100%;
              height: 100%;
              /deep/ .el-input__inner {
                height: 100%;
                // background: transparent;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #060b4c;
                // border: 1px solid rgba(255, 255, 255, 0.25);
                border-radius: 9px;
                &::placeholder {
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: rgba(16, 42, 85, 0.4);
                }
              }
            }
          }
        }
        .hm-cell {
          width: 870px;
          padding: 0;
          margin: 0 auto;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 15px;
          box-sizing: border-box;
          .form-txt {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
        }
      }
    }
    .submit-btn {
      width: 160px;
      height: 50px;
      margin: 0 auto;
      background: #2871ff;
      border-radius: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .move-top {
    animation: option-top 0.5s forwards ease-in-out;
  }
  .move-hide {
    animation: option-top-hide 0.5s forwards ease-in-out;
  }
  @keyframes option-top {
  0% {
    transform: translateY(-300px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes option-top-hide {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-300px);
  }
}
</style>
