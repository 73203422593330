var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navgation',{attrs:{"bgColor":"#060B4C","isPageHome":true}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowAdsorption),expression:"isShowAdsorption"}],class:{
      'option-cell2': true,
      fixed: true,
      'move-top': _vm.isShowXD,
      'move-hide': _vm.isShowXD2,
    }},[_c('div',{staticClass:"form-cell"},[_c('div',{staticClass:"hm-cell"},[_c('div',{staticClass:"form-item form-ml"},[_c('el-input',{attrs:{"placeholder":"请输入您所在的城市","maxlength":"11"},model:{value:(_vm.joinForm.city),callback:function ($$v) {_vm.$set(_vm.joinForm, "city", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"joinForm.city"}})],1),_c('div',{staticClass:"form-item form-ml"},[_c('el-input',{attrs:{"placeholder":"请输入您的姓名","maxlength":"11"},model:{value:(_vm.joinForm.name),callback:function ($$v) {_vm.$set(_vm.joinForm, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"joinForm.name"}})],1),_c('div',{staticClass:"form-item form-ml form-mr"},[_c('el-input',{attrs:{"placeholder":"请输入您的手机号","maxlength":"11","minlength":"11"},model:{value:(_vm.joinForm.mobile),callback:function ($$v) {_vm.$set(_vm.joinForm, "mobile", _vm._n($$v))},expression:"joinForm.mobile"}})],1),_c('div',{staticClass:"submit-btn",on:{"click":_vm.applyJoin}},[_vm._v("提交")])])])]),_c('div',{staticClass:"product-sjsdzjqr-container"},[_vm._m(0),_c('section',{staticClass:"sjsdzjqr-video"},[_c('div',{staticClass:"video-shadow"}),_c('video',{ref:"sjsdzjqrVideRef",attrs:{"src":"/videos/jzbs.mp4","autoplay":"","loop":"","muted":""},domProps:{"muted":true}})]),_c('section',{staticClass:"service"},[_c('div',{staticClass:"service-content",class:{
          'pre-hide-100': true,
          'move-bottom-100': _vm.isShowService,
        }},[_c('div',{staticClass:"service-title section-title"},[_vm._v("十角兽代账机器人可以：")]),_c('div',{staticClass:"service-module"},_vm._l((_vm.serviceModules),function(serviceModuleItem,serviceModuleIndex){return _c('div',{key:serviceModuleItem.title,staticClass:"service-module-item",on:{"mouseover":function($event){_vm.serviceHoverIndex = serviceModuleIndex}}},[_c('div',{staticClass:"service-module-item-content"},[_c('img',{attrs:{"src":serviceModuleItem.img,"alt":""}}),_c('div',{staticClass:"service-module-item-content-title"},[_vm._v(" "+_vm._s(serviceModuleItem.title)+" ")])])])}),0)])]),_c('section',{staticClass:"jzbs"},[_c('div',{staticClass:"jzbs-content",class:{
          'pre-hide-100': true,
          'move-bottom-100-1': _vm.isShowJzbs,
        }},[_c('div',{staticClass:"jzbs-title section-title"},[_vm._v("您每月只需要")]),_c('div',{staticClass:"jzbs-steps"},_vm._l((_vm.jzbsList),function(item,index){return _c('div',{key:'total' + index,staticClass:"jzbs-steps-item"},[_c('div',{staticClass:"item-box"},[_c('img',{staticClass:"item-img",attrs:{"src":item.img,"alt":""}}),_c('div',{staticClass:"item-content"},[_vm._v(_vm._s(item.content))])]),(index !== _vm.jzbsList.length - 1)?_c('img',{staticClass:"right-arrow",attrs:{"src":require("@/assets/images/partner/right-arrow.png"),"alt":""}}):_vm._e()])}),0)])]),_c('section',{staticClass:"advantage"},[_c('div',{staticClass:"advantage-content",class:{
          'pre-hide-100': true,
          'move-bottom-100-2': _vm.isShowAdvantage,
        }},[_c('div',{staticClass:"advantage-title section-title"},[_vm._v("产品优势")]),_vm._m(1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"banner"},[_c('div',{staticClass:"banner-content"},[_c('div',{staticClass:"banner-text"},[_c('div',{staticClass:"banner-text-title"},[_vm._v("自助报税，三年仅需1580元")]),_c('div',{staticClass:"banner-text-introduce"},[_vm._v(" 十角兽代账机器人，轻松3步，一键报税 ")]),_c('div',{staticClass:"banner-text-qr"},[_c('img',{staticClass:"qr-img",attrs:{"src":require("@/assets/images/product/dzjqr-qr.png"),"alt":""}}),_c('div',{staticClass:"qr-label"},[_vm._v("扫码体验")])])]),_c('img',{staticClass:"phone-img",attrs:{"src":require("@/assets/images/product/dzjqr-phone.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantage-modules"},[_c('div',{staticClass:"advantage-modules-left"},[_c('div',{staticClass:"advantage-modules-left-title"},[_vm._v("十角兽代理记账")]),_c('div',{staticClass:"advantage-modules-left-list"},[_c('div',{staticClass:"advantage-modules-left-list-item"},[_c('img',{attrs:{"src":require("@/assets/images/product/advantage-1.png"),"alt":""}}),_c('span',[_vm._v("仅需600元/年")])]),_c('div',{staticClass:"advantage-modules-left-list-item"},[_c('img',{attrs:{"src":require("@/assets/images/product/advantage-2.png"),"alt":""}}),_c('span',[_vm._v("7*24h财税专家1v1咨询 ")])]),_c('div',{staticClass:"advantage-modules-left-list-item"},[_c('img',{attrs:{"src":require("@/assets/images/product/advantage-3.png"),"alt":""}}),_c('span',[_vm._v("3步完成记账报税")])]),_c('div',{staticClass:"advantage-modules-left-list-item"},[_c('img',{attrs:{"src":require("@/assets/images/product/advantage-4.png"),"alt":""}}),_c('span',[_vm._v("数据实时在线查看")])])])]),_c('div',{staticClass:"advantage-modules-right"},[_c('div',{staticClass:"advantage-modules-right-title"},[_vm._v("个人、代账公司")]),_c('div',{staticClass:"advantage-modules-right-list"},[_c('div',{staticClass:"advantage-modules-right-list-item"},[_vm._v(" 一年两三千元 ")]),_c('div',{staticClass:"advantage-modules-right-list-item"},[_vm._v("回复消息慢")]),_c('div',{staticClass:"advantage-modules-right-list-item"},[_vm._v("流程节点多")]),_c('div',{staticClass:"advantage-modules-right-list-item"},[_vm._v("数据不透明")])])])])
}]

export { render, staticRenderFns }